import Link from "next/link";
import { useContext } from "react";
import Widget from "@components/widget";
import { toCapitalize } from "@utils/toCapitalize";
import accountData from "@data/account-menu.json";
import { catsSortByLabel } from "@utils/method";
import { Col, Container, Row } from "react-bootstrap";
import { getProductsUniqueCategories } from "@utils/product";
import { ProductsContext } from "@global/ProductsContext";





const WidgetsOne = ({ className, dark }) => {
  const { products } = useContext(ProductsContext);
  const cats = getProductsUniqueCategories(products, 6);
  const categories = catsSortByLabel(cats);

  return (
    <div
      className={`footer-container tt-footer-col tt-color-scheme-0${
        dark ? 3 : 1
      } ${className ? className : ""}`}
    >
      <Container>
        <Row>
          <Col md={6} lg={3} xl={3}>
            <Widget title="MI CUENTA:">
              <div className="estilosWidgetOne">

                <ul className="tt-list">
                  {accountData.map((item) => (
                    <li key={item.id}>
                      <Link href={item.link}>
                        {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Widget>
          </Col>

          <Col md={6} lg={3} xl={3}>
              <Widget title="CONTACTO">
                <div className="estilosWidgetOne">
                  <address>
                    <p>
                      Teléfono: +598 092 034 560
                    </p>
                    <p>
                      Horarios: Lunes a Viernes 09:00 a 18:00 y  Sábados de 9:00 a 13:00.<br />
                           
                    </p>
                    <p>
                      E-mail:{" "}
                      <Link href="mailto:info@sanmarino.com.uy">
                        info@sanmarino.com.uy
                      </Link>
                    </p>
                  </address>
                </div>
              </Widget>
            </Col>
          <Col md={6} lg={3} xl={3}>
            <Widget title="LOCAL MALVÍN">
              <div className="estilosWidgetOne">
                <address>
                  <p>
                    <span>Valencia 1790, Malvín</span> <br />
                    <span></span>
                  </p>
                  <p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.767804005493!2d-56.1061241846732!3d-34.88717658005847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f86d01a1d3be1%3A0x8d5a8424d613746e!2sValencia%201790%2C%2011400%20Montevideo%2C%20Departamento%20de%20Montevideo!5e0!3m2!1sen!2suy!4v1660654614466!5m2!1sen!2suy"
                        width="200" height="150" style={{border:0}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade" />
                  </p>
                </address>
              </div> 
            </Widget>
          </Col>
          <Col md={6} lg={3} xl={3}>
            <Widget title="LOCAL OUTLET VERACIERTO">
              <address>
                <p>
                  <span className="estilosWidgetOne">Veracierto 3180</span> <br />
                </p>
                <p>
                  <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3273.9352853050327!2d-56.12818298815556!3d-34.85785337275113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80ba8397c257%3A0x831c3a206477e587!2sVeracierto%203180%2C%2012100%20Montevideo%2C%20Departamento%20de%20Montevideo!5e0!3m2!1sen!2suy!4v1681321472671!5m2!1sen!2suy"
                      width="200" height="150" style={{border:0}} allowFullScreen="" loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade" />
                </p>
              </address>
            </Widget>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WidgetsOne;
